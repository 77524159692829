import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FormattedMessage } from 'react-intl'

import SEO from '../components/seo'
import Carousel from '../components/carousel'
import classroom from '../images/hero-about.jpg'

const AboutPage = (props) => (
  <Layout>
    <SEO title="About" />
    <Carousel
      title={<FormattedMessage id="navbar-1" />}
      subtitle={<FormattedMessage id="about--subtitle" />}
      size={'is-medium'}
      // color={"is-primary"}
      img={classroom}
      imgalt="Our classroom"
    />
    <div className="main container">
      <div className="columns" id="about--columns">
        <div className="column is-flex is-one-third">
          <GatsbyImage
            image={props.data.school.childImageSharp.gatsbyImageData}
            alt="school"
            className="about-image"
          />
        </div>
        <div className="column">
          <h1 className="title">
            <FormattedMessage id="about--card1-heading" />
          </h1>
          <p>
            <FormattedMessage id="about--card1-content-p1" />
          </p>
          <p>
            <FormattedMessage id="about--card1-content-p2" />
          </p>
        </div>
      </div>
      <div className="columns column-reverse" id="about--columns">
        <div className="column is-flex is-one-third">
          <GatsbyImage
            image={props.data.book.childImageSharp.gatsbyImageData}
            alt="book"
            className="about-image"
          />
        </div>
        <div className="column">
          <h1 className="title">
            <FormattedMessage id="about--card2-heading" />
          </h1>
          <p>
            <FormattedMessage id="about--card2-content-p1" />
          </p>
          <p>
            <FormattedMessage id="about--card2-content-p2" />
          </p>
        </div>
      </div>

      <div className="columns" id="about--columns">
        <div className="column is-flex is-one-third">
          <GatsbyImage
            image={props.data.rocket.childImageSharp.gatsbyImageData}
            alt="rocket"
            className="about-image"
          />
        </div>
        <div className="column">
          <h1 className="title">
            <FormattedMessage id="about--card3-heading" />
          </h1>
          <p>
            <FormattedMessage id="about--card3-content" />
          </p>
        </div>
      </div>
      <h1 className="about--timeline-title">
        <span>
          <FormattedMessage id="about--timeline-title" />
        </span>
      </h1>
      <div className="timeline">
        <div className="timeline-container timeline-left">
          <div className="timeline-content">
            <h2>2016</h2>
            <p>
              <FormattedMessage id="about--timeline-content1" />
            </p>
          </div>
        </div>
        <div className="timeline-container timeline-right">
          <div className="timeline-content">
            <h2>2017</h2>
            <GatsbyImage
              image={props.data.logo.childImageSharp.gatsbyImageData}
              alt="logo"
              className="about-image"
            />

            <p>
              <FormattedMessage id="about--timeline-content2" />
            </p>
          </div>
        </div>
        <div className="timeline-container timeline-left">
          <div className="timeline-content">
            <h2>2017</h2>
            <GatsbyImage
              image={props.data.room.childImageSharp.gatsbyImageData}
              alt="room"
              className="about-image"
            />
            <p>
              <FormattedMessage id="about--timeline-content3" />
            </p>
          </div>
        </div>
        <div className="timeline-container timeline-right">
          <div className="timeline-content">
            <h2>2019</h2>
            <GatsbyImage
              image={props.data.office.childImageSharp.gatsbyImageData}
              alt="office"
              className="about-image"
            />
            <p>
              <FormattedMessage id="about--timeline-content4" />
            </p>
          </div>
        </div>
        <div className="timeline-container timeline-left">
          <div className="timeline-content">
            <h2>2020</h2>
            <GatsbyImage
              image={props.data.home.childImageSharp.gatsbyImageData}
              alt="home"
              className="about-image"
            />
            <p>
              <FormattedMessage id="about--timeline-content5" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const aboutSquareImage = graphql`
  fragment aboutSquareImage on File {
    childImageSharp {
      gatsbyImageData(width: 256, height: 256, layout: FIXED)
    }
  }
`

export const aboutRectImage = graphql`
  fragment aboutRectImage on File {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
`

export const query = graphql`
  query {
    classroom: file(relativePath: { eq: "hero-about.jpg" }) {
      ...aboutSquareImage
    }
    school: file(relativePath: { eq: "about-1.jpg" }) {
      ...aboutSquareImage
    }
    book: file(relativePath: { eq: "about-2.jpg" }) {
      ...aboutSquareImage
    }
    rocket: file(relativePath: { eq: "about-3.jpg" }) {
      ...aboutSquareImage
    }
    logo: file(relativePath: { eq: "about-timeline2.jpg" }) {
      ...aboutRectImage
    }
    room: file(relativePath: { eq: "about-timeline3.jpg" }) {
      ...aboutRectImage
    }
    office: file(relativePath: { eq: "about-timeline4.jpg" }) {
      ...aboutRectImage
    }
    home: file(relativePath: { eq: "about-timeline5.jpg" }) {
      ...aboutRectImage
    }
  }
`

export default AboutPage
